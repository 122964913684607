import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-0600/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-0600 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-0600/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-0600/' crumbLabel="IN-0600" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-0600-default",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0600-default",
        "aria-label": "in 0600 default permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0600 Default`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3001 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3003 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3005 Indoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-3011/"
        }}>{`IN-3010/1 Indoor Camera`}</a></li>
      <li parentName="ul">{`IN-2901 Outdoor Camera`}</li>
      <li parentName="ul">{`IN-2904 Outdoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-2905_V2/"
        }}>{`IN-2905 V2 Outdoor Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/"
        }}>{`IN-6011/2 HD Indoor Camera`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/1bba8/Angle_of_View_6mm_Lense.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC6ElEQVQ4y1WTa4/aVhCG/eerKqrUbCu1SfspCdtGoVrITV1utsFgFtjNAr5gfMX4hrObRv0HT3VOWJp8eHVGc2beeWfOHGWwGKJeG/Tn4hxJfPGNGNwY9LUuvTdtuu9eM5hqDETM4kuMfm0wMQyGIwPP29Js/oWi+VPU4AotmH4D1Z+iRTPUW51u6xW9v9tonokmYo85xsbkw+KG5criUH8kjhOU4VJD20xQt1OZoB7xYGvC75pom6m0T3e+yejDhG7HwnIiDnWNZdko2sZkuNTRnTGaf3Ui/BqS6FjwgVR04Lg2NwsfP0ypDjXuxkMZbE363hh1qTJY6+jhjGEwQ/ev0IOZTB6IDgSJjBX2BHu3pdqllEVOVhSU1QHH3aDM7TmrxOUmtrl2F1xOLnm/6HO51Hg77zIK54zja1lA86bMwluCfUxZVGRRQp4XZPkDoYsSBUt5eahqNlufzrv3dF6/4cezM7579D2PHv/A+cVLOnqfIIkpioqyqnFsh5kxIUn35EVJUR7YiJaDYCUrVHXNxJyyzwuSKMYcqLRbbZ4+/Y3mqyatVpswjCnKirwssS2b6Wh8IpQKHRclDC2yvKT+eCf3yRhPUPUhjUaD33/6mZfn5/QGA/ThkCCMJKGEaDWKycvq1PLG81CiyCEvKllll+4ZGWMuLlr88usTXjQaPHl8RvOPP+n0eqT7TM4sjGKCrU+e7MiK8v8ZCoVx7JGXh5NTKL27/8Tnz/9y/+kf6rt7OfxqtyfNcparNberNbsgJEszKUTkCtVybZLEl4T7LMcPIqlS2EKNOAUyMbddSmA7JLuUVBAkO3JxV5TkRSF/ilybJAnkC3lbH00fSadQmh3X4QSRuM8oxBzFq6Z7MjGCspJF1pbDcrlCybKUQ30nlVi2KxUWx0F/jfyBVKgKIiLHJctyiurA1g8YGSamOUWxrBW3y7X8h2vLlgoFeS5b+RZyVtWBONnx/NkzqUwUF/HCJ/L/A/rpDUB1h5oVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/e4706/Angle_of_View_6mm_Lense.avif 230w", "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/d1af7/Angle_of_View_6mm_Lense.avif 460w", "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/5e9eb/Angle_of_View_6mm_Lense.avif 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/a0b58/Angle_of_View_6mm_Lense.webp 230w", "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/bc10c/Angle_of_View_6mm_Lense.webp 460w", "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/7eb86/Angle_of_View_6mm_Lense.webp 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/81c8e/Angle_of_View_6mm_Lense.png 230w", "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/08a84/Angle_of_View_6mm_Lense.png 460w", "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/1bba8/Angle_of_View_6mm_Lense.png 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7590d0c7e7742a4de2aae2e3ccaed4a/1bba8/Angle_of_View_6mm_Lense.png",
              "alt": "IN-0600 Default",
              "title": "IN-0600 Default",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The angle of view of the 6.0mm default lense. Field Angle (D x H x V): 45.9° x 36.8° x 28.4°.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/556702d8f540ee41dc9b8164d0dfea25/c08c5/6mm_IRcut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGfplwEiuUf/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIRAAMi/9oACAEBAAEFAn9GTBtzD0KaLv/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAARARITEyYf/aAAgBAQAGPwLpQ1YrRln/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMVGR/9oACAEBAAE/IaCjRuBLz3LM1EwbPmQny4E5+2//2gAMAwEAAgADAAAAELA//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAEDAQE/EMI//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEf/aAAgBAgEBPxBp7Cn/xAAcEAACAgMBAQAAAAAAAAAAAAABEQAxIUFRYZH/2gAIAQEAAT8QIMQIj3FPcdlHhfi+QUc7AokaWhGouEwFquYCCTQi6KPJ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/556702d8f540ee41dc9b8164d0dfea25/e4706/6mm_IRcut.avif 230w", "/en/static/556702d8f540ee41dc9b8164d0dfea25/d1af7/6mm_IRcut.avif 460w", "/en/static/556702d8f540ee41dc9b8164d0dfea25/e6133/6mm_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/556702d8f540ee41dc9b8164d0dfea25/a0b58/6mm_IRcut.webp 230w", "/en/static/556702d8f540ee41dc9b8164d0dfea25/bc10c/6mm_IRcut.webp 460w", "/en/static/556702d8f540ee41dc9b8164d0dfea25/0ba47/6mm_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/556702d8f540ee41dc9b8164d0dfea25/e83b4/6mm_IRcut.jpg 230w", "/en/static/556702d8f540ee41dc9b8164d0dfea25/e41a8/6mm_IRcut.jpg 460w", "/en/static/556702d8f540ee41dc9b8164d0dfea25/c08c5/6mm_IRcut.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/556702d8f540ee41dc9b8164d0dfea25/c08c5/6mm_IRcut.jpg",
              "alt": "IN-0600 Default",
              "title": "IN-0600 Default",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 6mm default lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <h2 {...{
      "id": "in-0600-default-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0600-default-lense-chart",
        "aria-label": "in 0600 default lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0600 Default Lense Chart`}</h2>
    <p>{`Lense chart for the 6mm lense for the 1/4" CMOS sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b38774f2672a2529f223ec7dd86a1b98/c7dcc/Lense_Chart_6mm_deutsch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuklEQVQoz22Sy25TMRCG+5IsIgRdp6VVtogN6p6KLQobJC5PkdJdniAriAQkjUNyLr577Jk5Rue4TSrgl+WFx9/MP/acICYijDGmlIgIEYUQy0FCiG8PEkIQETPTI50g4mr1a7n87pzNvTrvndYKAFJKIQQ/KEbIOTP3fH+p65j5xDs3efXyxdnoy8f3ARIAGKM/ff6gtcwl2aCcc4DQNHXTNFrrB9j7t9N3T05HV1ev66ZZLBYh+PX6h7XmADOzMVopVWwj4j1srQ0Azvs0HBFh13FZBfbOSiVjjEiolEwpHm2HEEobOWciKg5LDDENBWUI3ljdygYAvPfGmHvYOfcvzMzWGqVlvyspZQsAJWl5xf/AzFzCUsmqrqSSQ9lQMh4uHG0bY4ioxJDQGL3bb/fVTsq2nB8AHvT4Fft/7nIfAwh3m9VG3Dlryzz8Va30hYPKUPUwEf7ebVfrn1LJUq0kPsA9xjyfz6fT6bPnp+Px+Wj0dDa76eFWNmK7IWLC4+jFGAucUkLElNJsdvPm+vricjI+O7+4nHy9ve2/6lANAIrJruvatq2qar/f13WdUirOEbF0TkQhhD/Pw9vQ/f3zegAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b38774f2672a2529f223ec7dd86a1b98/e4706/Lense_Chart_6mm_deutsch.avif 230w", "/en/static/b38774f2672a2529f223ec7dd86a1b98/d1af7/Lense_Chart_6mm_deutsch.avif 460w", "/en/static/b38774f2672a2529f223ec7dd86a1b98/e1e16/Lense_Chart_6mm_deutsch.avif 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b38774f2672a2529f223ec7dd86a1b98/a0b58/Lense_Chart_6mm_deutsch.webp 230w", "/en/static/b38774f2672a2529f223ec7dd86a1b98/bc10c/Lense_Chart_6mm_deutsch.webp 460w", "/en/static/b38774f2672a2529f223ec7dd86a1b98/de042/Lense_Chart_6mm_deutsch.webp 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b38774f2672a2529f223ec7dd86a1b98/81c8e/Lense_Chart_6mm_deutsch.png 230w", "/en/static/b38774f2672a2529f223ec7dd86a1b98/08a84/Lense_Chart_6mm_deutsch.png 460w", "/en/static/b38774f2672a2529f223ec7dd86a1b98/c7dcc/Lense_Chart_6mm_deutsch.png 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b38774f2672a2529f223ec7dd86a1b98/c7dcc/Lense_Chart_6mm_deutsch.png",
              "alt": "IN-0600 Default",
              "title": "IN-0600 Default",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b7464e69ec0d40b43e828d76c9fb6616/c08c5/IN-2905_6mm_Lense.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAF5oFEQn//EABsQAAIBBQAAAAAAAAAAAAAAAAIDAQAQEyEi/9oACAEBAAEFAuzXlIaE2zeV7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAARASIgIWH/2gAIAQEABj8Ci6NW6Nxh/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERIUEx/9oACAEBAAE/IV2SDZmGeHohaMV7wlY0Wqw//9oADAMBAAIAAwAAABC//wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQEBAAMBAAAAAAAAAAAAAREAIUFRcWH/2gAIAQEAAT8QaCzmVp318puNgKXZOuTTHBT4mfANU/MDDpv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b7464e69ec0d40b43e828d76c9fb6616/e4706/IN-2905_6mm_Lense.avif 230w", "/en/static/b7464e69ec0d40b43e828d76c9fb6616/d1af7/IN-2905_6mm_Lense.avif 460w", "/en/static/b7464e69ec0d40b43e828d76c9fb6616/e6133/IN-2905_6mm_Lense.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b7464e69ec0d40b43e828d76c9fb6616/a0b58/IN-2905_6mm_Lense.webp 230w", "/en/static/b7464e69ec0d40b43e828d76c9fb6616/bc10c/IN-2905_6mm_Lense.webp 460w", "/en/static/b7464e69ec0d40b43e828d76c9fb6616/0ba47/IN-2905_6mm_Lense.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b7464e69ec0d40b43e828d76c9fb6616/e83b4/IN-2905_6mm_Lense.jpg 230w", "/en/static/b7464e69ec0d40b43e828d76c9fb6616/e41a8/IN-2905_6mm_Lense.jpg 460w", "/en/static/b7464e69ec0d40b43e828d76c9fb6616/c08c5/IN-2905_6mm_Lense.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b7464e69ec0d40b43e828d76c9fb6616/c08c5/IN-2905_6mm_Lense.jpg",
              "alt": "IN-0600 Default",
              "title": "IN-0600 Default",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/1bba8/Instar_6mm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAD1klEQVQ4y22N+U/TBxjGv//IsiPBxXiAWC1HS1t60JYi7TgEYYgwcZQip05ATgVCRWQWHZFLihhAsUUDTGHOTOamYBwWGbChjImIMveDuxTazwJhy2L2JE/e5Hmf9/MKHo+HFa3M5WU3b8rtdq/mbo9ntfN//ud+xcIqyO1eSVYXfyy5uTk6wcTsPEueN/GetQfLuN0ePGsg9/LSvw3hv/WB26MkHShBrDEhM8YRn1XA8bOd9A4NM7OwyPIb+NeLj3nhugt/vmT8hxmeLr5AWFp6xbej4yQdKGZjoIoNvv5IFWpUOgMqfRgKvZG4FAv7DhaSmlfGUVsDPYNDTP04zfPh6zzu7eKvqXsM3LzDjeuDCJcbayg9kElkqI51673RhuiIjYokOTGBwuJCjlTXkFdYTHpGNqkZ2azbLMJPoUGtDycmOpaK3Fwej3xNh6OHtpp8BFv2Phry0zmZY0YrkRIRFUNYqAGVUk3B0QrKbfXEfbSfJHMuFbWnEQcpCYuOJkSrZZOPL/rQHZxracbW4qDy0xaEwwlR5Ow0khNlRBcgJiHFTOQHEWg0IZQeO8Epewf784+SW1JFfXsX24KCkSnVaLRaJHIFIQoZVdXV1PXepfXBa4TcqFCyIw1YduiQ+3qTdrCAxOQU9HoDZdUnqD/XSXOXgzbHFWwtbWwLkBEoVyJTBCMKlCCXSNlnTqOq8xr2kXmE7CgdyVoFqeFaJN4byC0pZ/aVm5nffmfowQQylRa5UkuwWos0WIXIX4okSE6EUU+gxJ9QUwx70vMpafucvFP9CGajkgS1lF0qKT5e75FVVMpTYBG4M/2QAIWareIAxIFBbA8KYrNYjEgkIjPWgEG2nb0FNtKt7dRemyTRUo5gMamIVvih89+G1ztvk1NWya/AS8A1v4BIIiMiNh5dmImt/hK2iP1Yv3ETAT6bCNaEkNHwDfn1TkparhIfk4SwN1xBXKiUneEaQsL07DZbqL94ifb+q5y56CRtTyqt1jpiTLvw8hHx/uYtvPWuF34KJXnNfZxxQVHTFQ4VW2mssCD0NBfT01SEs7mIXns5l1sr6DpTSldjOV1nrTiarXTUlXD6WB41VYewHsmi7HDmKsR2Y4bK/kdkl9dRW5TCxZMZCJO37Ezdbmfqlp3pW3ZmRzp4MtrN/P1LzN27wM+jF5gb62Zh3MHCmIPn3zsZ+7KF3THxmAwmPv4wCYvZzPnaLO70HEeYdzlYWPPiRB8vHg2u+peHAzyb7GPe5eTJWA9z953M3etm7rtuZoc7mPqqifEvPuOnoSZOWT/h7kA9z1wd/A02xTgMGPMSJQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/e4706/Instar_6mm.avif 230w", "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/d1af7/Instar_6mm.avif 460w", "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/5e9eb/Instar_6mm.avif 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/a0b58/Instar_6mm.webp 230w", "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/bc10c/Instar_6mm.webp 460w", "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/7eb86/Instar_6mm.webp 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/81c8e/Instar_6mm.png 230w", "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/08a84/Instar_6mm.png 460w", "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/1bba8/Instar_6mm.png 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/21d0a65fec517bdc7c1bd4c0cdef83e5/1bba8/Instar_6mm.png",
              "alt": "IN-0600 Default",
              "title": "IN-0600 Default",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dcbe074331645b05aacf8ed853b95f3b/c08c5/Werbebild_II.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBAv/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAcQdaTWnI//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhAREv/aAAgBAQABBQLVXUa0UWz/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAwEBPwGUv//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AbW//8QAFhABAQEAAAAAAAAAAAAAAAAAMRAg/9oACAEBAAY/AmOP/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERQWH/2gAIAQEAAT8h5hNPSWC0pa6IKkf/2gAMAwEAAgADAAAAELA//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QIf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EKU//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFhkaH/2gAIAQEAAT8QfrcxKqa0dxYnls4hNgoChtDGAavqf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dcbe074331645b05aacf8ed853b95f3b/e4706/Werbebild_II.avif 230w", "/en/static/dcbe074331645b05aacf8ed853b95f3b/d1af7/Werbebild_II.avif 460w", "/en/static/dcbe074331645b05aacf8ed853b95f3b/e6133/Werbebild_II.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dcbe074331645b05aacf8ed853b95f3b/a0b58/Werbebild_II.webp 230w", "/en/static/dcbe074331645b05aacf8ed853b95f3b/bc10c/Werbebild_II.webp 460w", "/en/static/dcbe074331645b05aacf8ed853b95f3b/0ba47/Werbebild_II.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dcbe074331645b05aacf8ed853b95f3b/e83b4/Werbebild_II.jpg 230w", "/en/static/dcbe074331645b05aacf8ed853b95f3b/e41a8/Werbebild_II.jpg 460w", "/en/static/dcbe074331645b05aacf8ed853b95f3b/c08c5/Werbebild_II.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dcbe074331645b05aacf8ed853b95f3b/c08c5/Werbebild_II.jpg",
              "alt": "IN-0600 Default",
              "title": "IN-0600 Default",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.0 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8.1 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<  -18.6%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45.9° x 36.8° x 28.4°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.0 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      